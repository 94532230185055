var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Role" },
    [
      _vm.formVisible
        ? [
            _c(
              "appForm",
              {
                attrs: { "form-list": _vm.formList },
                on: { getSearchCallback: _vm.getSearchCallback },
              },
              [
                _c(
                  "template",
                  { slot: "customButton" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary", prefix: "add-one" },
                        on: { click: _vm.addRole },
                      },
                      [_vm._v(" 添加角色 ")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c("custom-table", {
              attrs: {
                columns: _vm.columns,
                "data-source": _vm.dataSource,
                "table-ref": ".Role",
                "row-key": "flowRoleName",
                pagination: false,
              },
              on: { change: _vm.loadDataList },
              scopedSlots: _vm._u(
                [
                  {
                    key: "operation",
                    fn: function (ref) {
                      var scope = ref.scope
                      return [
                        _c(
                          "span",
                          { staticClass: "operation-btn-box" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "link" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editRole(scope.record)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                            _c(
                              "a-button",
                              {
                                staticClass: "delete",
                                attrs: { type: "link" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delet(scope.record)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3258938202
              ),
            }),
          ]
        : _c(
            "div",
            [
              _c("AddRole", {
                attrs: {
                  "form-type": _vm.formType,
                  "flow-role-id": _vm.flowRoleId,
                },
                on: {
                  back: function ($event) {
                    _vm.formVisible = true
                  },
                  getInit: _vm.getInit,
                },
              }),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }