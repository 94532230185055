var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-role" },
    [
      _c(
        "div",
        { staticClass: "category-header" },
        [
          _c("a-button", {
            attrs: { type: "iconBtn", icon: "left" },
            on: {
              click: function ($event) {
                return _vm.$emit("back")
              },
            },
          }),
          _c("div", { staticClass: "category-title" }, [
            _vm._v(_vm._s(_vm.formType ? "添加" : "编辑") + "审批角色"),
          ]),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { ref: "name", attrs: { label: "角色名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: { placeholder: "1-20个字符，不能与现有角色名称重复" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
              _c(
                "a-tooltip",
                {
                  staticClass: "item",
                  attrs: { placement: "right", trigger: "hover" },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("span", [
                      _vm._v(
                        "角色名称请不要包含部门负责人、HRBP、汇报对象和虚拟组织负责人系统预留名称"
                      ),
                    ]),
                  ]),
                  _c("a-icon", {
                    staticClass: "circle_icon",
                    attrs: { type: "exclamation-circle", theme: "filled" },
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "appForm",
        { on: { getSearchCallback: _vm.getSearchCallback } },
        [
          _c(
            "template",
            { slot: "customButton" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", prefix: "add-one" },
                  on: { click: _vm.addCategory },
                },
                [_vm._v(" 添加员工 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("custom-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.perDataList,
          "offset-bottom": 52,
          "table-ref": ".add-role",
          "is-footer": true,
          "row-key": "onJobId" ? "onJobId" : "phoneNum",
          pagination: false,
        },
        scopedSlots: _vm._u([
          {
            key: "operation",
            fn: function (ref) {
              var scope = ref.scope
              return [
                _c(
                  "span",
                  { staticClass: "operation-btn-box" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.setPermissions(scope.record)
                          },
                        },
                      },
                      [_vm._v(" 设置权限 ")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticClass: "delete",
                        attrs: { type: "link" },
                        on: {
                          click: function ($event) {
                            return _vm.delet(scope)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "vadd_button" },
        [
          _c(
            "a-button",
            { staticClass: "close_button", on: { click: _vm.onClose } },
            [_vm._v("取消")]
          ),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.addRose } },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _c(
        "Drawer",
        {
          attrs: {
            title: _vm.titleMsg,
            visible: _vm.visible,
            closable: false,
            size: "small",
          },
          on: {
            close: function ($event) {
              _vm.visible = false
            },
            onSubmit: _vm.onSubmin,
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "perRuleForm",
              attrs: {
                model: _vm.formStaff,
                rules: _vm.perRules,
                "label-col": _vm.labelColW,
                "wrapper-col": _vm.wrapperColW,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "姓名", prop: "userInfo" } },
                [
                  !_vm.isSet
                    ? _c("PerTreeSelect", {
                        attrs: {
                          placeholder: "请选择员工",
                          "mode-type": "default",
                        },
                        on: { getUserInfo: _vm.handleChange },
                        model: {
                          value: _vm.formStaff.userInfo,
                          callback: function ($$v) {
                            _vm.$set(_vm.formStaff, "userInfo", $$v)
                          },
                          expression: "formStaff.userInfo",
                        },
                      })
                    : _vm._e(),
                  _vm.isSet
                    ? _c("a-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formStaff.staffName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formStaff, "staffName", $$v)
                          },
                          expression: "formStaff.staffName",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "所属部门", prop: "orgName" } },
                [
                  _c("a-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.formStaff.orgName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formStaff, "orgName", $$v)
                      },
                      expression: "formStaff.orgName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "手机号", prop: "phoneNumber" } },
                [
                  _c("a-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.formStaff.phoneNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.formStaff, "phoneNum", $$v)
                      },
                      expression: "formStaff.phoneNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "管理范围",
                    prop: "manageRange",
                    required: "",
                  },
                },
                [
                  _c("DepTreeSelect", {
                    attrs: { multiple: true, placholder: "请选择管理范围" },
                    on: {
                      perModelConfirm: _vm.perModelConfirm,
                      getSelectBlur: _vm.getSelectBlur,
                    },
                    model: {
                      value: _vm.formStaff.manageRange,
                      callback: function ($$v) {
                        _vm.$set(_vm.formStaff, "manageRange", $$v)
                      },
                      expression: "formStaff.manageRange",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }